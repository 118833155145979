/* Базовые стили для 3D-эффектов */
.perspective-1000 {
    perspective: 1000px;
}

.transform-style-3d {
    transform-style: preserve-3d;
}

.rotate-x-10 {
    transform: rotateX(10deg);
}

.rotate-y-5 {
    transform: rotateY(5deg);
}

.translate-z-10 {
    transform: translateZ(10px);
}

.translate-z-20 {
    transform: translateZ(20px);
}

/* Анимации для карточек платформы */
.platform-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.platform-card:hover {
    transform: translateZ(30px) scale(1.05);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-color: rgba(249, 115, 22, 0.5); /* orange-500 с прозрачностью */
}

/* Эффект платформы для контейнера */
.platform-container {
    transform: rotateX(10deg) rotateY(5deg);
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
}

.platform-container:hover {
    transform: rotateX(5deg) rotateY(3deg);
}

/* Тени для усиления 3D эффекта */
.platform-shadow {
    position: absolute;
    bottom: -30px;
    left: 5%;
    width: 90%;
    height: 30px;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 70%);
    transform: rotateX(90deg);
    transform-origin: top;
    z-index: -1;
    filter: blur(8px);
    opacity: 0.8;
}

/* Анимация появления карточек */
@keyframes card-float {
    0% {
        transform: translateZ(0) translateY(0);
    }
    50% {
        transform: translateZ(15px) translateY(-10px);
    }
    100% {
        transform: translateZ(0) translateY(0);
    }
}

.float-animation {
    animation: card-float 5s ease-in-out infinite;
}